import React from "react";
import {Global, css} from "@emotion/react";
import {Helmet} from "react-helmet";
import Header from "./header";
import Footer from "./footer";
import styled from "@emotion/styled";

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`

const Layout = ({children}) => {

    return(
        <LayoutWrapper>
            < Global
                styles = {
                    css`
          * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
          }

          * + * {
            margin-top: 1rem;
          }

          html,
          body {
            margin: 0;
            line-height: 1.5;
            font-size: 18px;
            background: #171C2F;
            color: #B8B8B9;

            // remove the margin from the div that gatsby inserts into.
            > div {
              margin-top: 0;
            }
          }

          header,
          footer {
            width: 100%;
          }

          section {
            display: flex;
            flex-direction: column;
            padding: 0 4%;
            
             h2 {
                margin-bottom: 1em;
                border-bottom: 1px solid gray;
                padding: 0.8em 0;
            }
          }

          section:first-of-type {
            h2 {
                margin-bottom: 0;
                border:none;
                padding: 0;
            }
            
          }

          main {
            margin-top: 0;
          }

          //Typography
          h1,
          h2,
          h3 {
            line-height: 1.1;
            color: whitesmoke;

            + * {
              margin-top: 1rem;
            }
          }

          h1 {
            font-size:clamp(1.8rem, 0.575rem + 5vw, 3.8rem);
          }

          h2 {
            font-size: clamp(1.6rem, 0.35rem + 4.0vw, 2.6rem);
          }

          h3 {
            font-size: clamp(1.3rem, 0.675rem + 3vw, 2.2rem);
          }

          p {
            font-size: clamp(1.1rem, 0.69888rem + 2.4vw, 1.4rem);
          }
        ;

          li {
            margin-top: 0.25rem;
          }
        `
                }
            />

            <Helmet>
                <html lang="en"/>
                <title>caseywoelfle.com</title>
                <meta name="description" content="site description"/>
            </Helmet>

            <Header/>

            <main>
                {children}
            </main>

            <Footer/>
        </LayoutWrapper>
    )
}

export default Layout;