import React from "react";
import styled from "@emotion/styled";

const CwFooter = styled.footer`
  min-height: 140px;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #2E3339;
`

const Footer = () => (
    <CwFooter>
        <p>&copy; 2021 Casey Woelfle</p>
    </CwFooter>
)

export default Footer;