import React from "react";
import {Link} from "gatsby";
import Nav from "./nav";
import styled from "@emotion/styled";
import facepaint from 'facepaint'
const mq = facepaint([
    '@media(min-width: 767px)',
    '@media(min-width: 1023px)',
    '@media(min-width: 1439px)'
])

const CwHeader = styled('header')`
  ${mq({
    display: 'flex',
    flexDirection: ['column', 'row'],
    justifyContent: 'space-around',
    alignItems: 'center',
    borderBottom: '2px solid #2e3339',
    minHeight:'140px',

    a:{
      textDecoration:'none',
      color:'inherit'
    }
  })};
`

const BrandName = styled(Link)`
  font-size: 1.5em;
  padding: 8%;
  margin-right: auto;
  
  &:hover{
    color: white;
  }
`

const Header = () => (
    <CwHeader>
        <BrandName to="/">Casey Woelfle</BrandName>
        <Nav/>
    </CwHeader>
)

export default Header;